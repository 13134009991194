import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Divider, Stack } from '@mui/material';

import NavBar from '../Theme/NavBar';
import { Anchor, SideBar } from '../Theme/SideBar';

import MUIAlert from './MuiAlert';
import MUIAvatar from './MuiAvatar';
import MUIBox from './MuiBox';
import MUIButton from './MuiButton';
import MUICard from './MuiCard';
import MUIForm from './MuiForm';
import MUIGrid from './MuiGrid';
import MUIIcon from './MuiIcon';
import MUIInput from './MuiInput';
import MUILink from './MuiLink';
import MUIList from './MuiList';
import MUIListItem from './MuiListItem';
import MUIMedia from './MuiMedia';
import MUIPaper from './MuiPaper';
import MUIRadio from './MuiRadio';
import MUIRichText from './MuiRichText';
import MUISlide from './MuiSlide';
import MUIStack from './MuiStack';
import MUITitle from './MuiTitle';
import MUITypography from './MuiTypography';

import Blog from './Blog';
import BlogSideBar from './BlogSideBar';
import FoodMenus from './FoodMenus';
import FoodMenuItem from './FoodMenuItem';

const BASE_API_URL = process.env.REACT_APP_DBADDRESS;

const componentMap = {
  'food-menu.menu-item': FoodMenuItem,
  'mui.alert': MUIAlert,
  'mui.anchor': Anchor,
  'mui.avatar': MUIAvatar,
  'mui.blog': Blog,
  'mui.blog-side-bar': BlogSideBar,
  'mui.buttons': MUIButton,
  'mui.card': MUICard,
  'mui.collection-box': MUIBox,
  'mui.collection-food-menu': FoodMenus,
  'mui.collection-form': MUIForm,
  'mui.collection-grid': MUIGrid,
  'mui.collection-list': MUIList,
  'mui.divider': Divider, // Special case, handled separately due to additional props
  'mui.svg-icon': MUIIcon,
  'mui.input': MUIInput,
  'mui.link': MUILink,
  'mui.list-item': MUIListItem,
  'mui.media': MUIMedia,
  'mui.navigation': NavBar, // Special case, handled separately due to different prop structure
  'mui.collection-paper': MUIPaper,
  'mui.radio': MUIRadio,
  'mui.rich-text': MUIRichText,
  'mui.side-bar': SideBar,
  'mui.slide': MUISlide,
  'mui.collection-stack': MUIStack,
  'mui.stack': Stack, // Special case, handled separately due to map function
  'mui.typography': MUITypography,
  'website.title': MUITitle,
};

const MUI = ({ type, content, params = '' }) => {
  if (type === 'mui.divider') {
    return (
      <>
        {content.spacingAbove && <br />}
        <Divider
          component={content.component}
          variant={content.variant}
          orientation={content.orientation}
          textAlign={content.textAlign}
          aria-hidden={content.ariaHidden}
          flexItem={content.flexItem}
        >
          {content.children}
        </Divider>
        {content.spacingBelow && <br />}
      </>
    );
  } else if (type === 'mui.navigation') {
    return <NavBar content={content.navigation.data} />;
  } else if (type === 'mui.spacing') {
    const items = new Array(content.breaks).fill(null);
    return (
      <>
        {items.map((_, i) =>
          <br key={i} />
        )}
      </>
    );
  } else if (type === 'mui.stack') {
    return (
      <Stack
        className="clients"
        direction={content.direction}
        spacing={content.spacing}
        useFlexGap={content.useFlexGap}
        justifyContent={content.justifyContent}
      >
        {content.images.data.map((image) => (
          <img
            src={`${BASE_API_URL}${image.attributes.url}`}
            alt={image.attributes.alternativeText}
            key={uuidv4()}
          />
        ))}
      </Stack>
    );
  }

  const Component = componentMap[type];
  return Component ? <Component content={content} params={params} /> : null;
};

export default MUI;