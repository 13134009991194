import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import MUI from './Mui';

const FoodMenus = ({ content: { food_menus } }) => (
  <>
    {food_menus.data.map((grid, index) => {
      const {
        className,
        spacing,
        direction,
        justifyContent,
        alignItems,
        padding,
        margin,
        xs = false,
        sm = false,
        md = false,
        lg = false,
        xl = false,
        xxl = false,
        content: gridContent,
      } = grid.attributes;

      return (
        <Grid
          container
          className={className || undefined} // Avoid passing empty string as className
          spacing={spacing}
          direction={direction}
          justifyContent={justifyContent}
          alignItems={alignItems}
          sx={{ padding, margin }} // Ensure padding and margin are meaningful or remove them
          key={`grid-${index}`}
        >
          {gridContent.map((mui, itemIndex) => (
            <Grid
              mobile={xs}
              tablet={sm}
              laptop={md}
              desktop={lg}
              qhd={xl}
              uhd={xxl}
              key={`item-${itemIndex}`}
            >
              <MUI type={mui.__component} content={mui} />
            </Grid>
          ))}
        </Grid>
      );
    })}
  </>
);

export default FoodMenus;