import React from 'react';

import { MuiMarkdown, getOverrides } from 'mui-markdown';
import { Chip, Typography } from '@mui/material';

const BASE_API_URL = process.env.REACT_APP_DBADDRESS;

const FoodMenuItem = ({ content, params }) => {
  console.log(content);
  return(
    <>
      <div>
        <Typography
          align="left"
          component="span"
          variant="h4"
        >{content.itemName}</Typography>
        <Chip
          label={content.itemPrice}
          sx={{
            backgroundColor: "#CCCCCC",
            float: "right",
          }}
        >
          {content.itemPrice}
        </Chip>
      </div>
      <br />
      <div>
        {content.itemPicture.data && (
          <>
            <img
              src={`${BASE_API_URL}${content.itemPicture.data?.attributes.url}`}
              alt={content.itemPicture.data?.attributes.alternativeText}
              width="33%"
              style={{
                borderRadius: "1rem",
                float: "left",
              }}
            />
            <MuiMarkdown
              options={{
                overrides: {
                  ...getOverrides(),
                  p: {
                    component: 'span',
                  },
                  span: {
                    component: 'span',
                    props: {
                      style: {
                        float: 'right',
                        width: '60%',
                      },
                    },
                  }
                }
              }}
            >
              {content.itemDescription}
            </MuiMarkdown>
          </>
        )}

        {!content.itemPicture.data && (
          <MuiMarkdown
            options={{
              overrides: {
                ...getOverrides(),
                p: {
                  component: 'span',
                },
                span: {
                  component: 'span',
                }
              }
            }}
          >
            {content.itemDescription}
          </MuiMarkdown>
        )}
      </div>
    </>
  );
}

export default FoodMenuItem;